import React from "react"
import Layout from "../components/layout"
import styled from 'styled-components'

// Components
import Accordion from 'react-bootstrap/Accordion'
import { Collaspe } from '../components'

const Image = styled.img`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`

const Faq = props => {

    return ( 
        <Layout 
            title = { 'FAQ' } 
        >
            <div className="site-About">
                <div 
                    style = {{
                        width: '100vw',
                        minHeight : '90vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        paddingTop: '5vh'
                    }}
                >

                    <h1>FAQ</h1>
                    <p>Here are some common questions which our customers ask.</p>
                    <Accordion
                        style = {{
                            width : '90%',
                            boxShadow: '0 0 4px'
                        }}
                    >
                        <Collaspe
                            title = { 'Why did my shopping cart become empty after I abandoned cart and came back to continue shopping?' }
                            eventKey = { '0' }
                        >
                            To enable cookies:
                            <ul
                                style = {{
                                    listStyleType: 'none'
                                }}
                            >
                                <li>
                                    Chrome: 
                                    <a 
                                        style = {{ color: '#ff5722' }} 
                                        href = {'https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en'} > Link</a>
                                </li>
                                <li>
                                    Microsoft Edge: 
                                    <a 
                                        style = {{ color: '#ff5722' }} 
                                        href = {'https://support.microsoft.com/en-us/search?query=enable%20cookies%20in%20edge'} > Link</a>
                                </li>
                                <li>
                                    Firefox: 
                                    <a 
                                        style = {{ color: '#ff5722' }} 
                                        href = { 'https://support.mozilla.org/en-US/kb/websites-say-cookies-are-blocked-unblock-them' } > Link</a>
                                </li>
                                <li>
                                    Safari: 
                                    <a 
                                        style = {{ color: '#ff5722' }} 
                                        href = { 'https://support.apple.com/en-ca/guide/safari/sfri11471/mac' } > Link</a>
                                </li>
                                <li>
                                    IOS: 
                                    <a 
                                        style = {{ color: '#ff5722' }} 
                                        href = { 'https://support.apple.com/en-ca/HT201265 '} > Link</a>
                                </li>
                                <li>
                                    Android: 
                                    <a 
                                        style = {{ color: '#ff5722' }} 
                                        href = { 'https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en' } > Link</a>
                                </li>
                            </ul>
                        </Collaspe>
                        <Collaspe
                            title = { 'Can I place an order online?' }
                            eventKey = { '1' }
                        >
                            <p><span style = {{ color: '#ff5722' }} >Siunsons.com</span> is a Business-to-Business (B2B) website. Any retailer, business, and organizational groups can register and order here. Minimum order quantities may apply.</p>
                        </Collaspe>
                        <Collaspe
                            title = { 'How can I place an order online?' }
                            eventKey = { '2' }
                        >
                            <p>To place an order, you must first register and become a registered customer. We’ll approve your registration as soon as possible.</p>
                            <ul
                                style = {{
                                    listStyleType: 'none'
                                }}
                            >
                                <li
                                    style = {{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems : 'center'
                                    }}
                                >
                                    Canadian customers, register&nbsp;
                                    <a 
                                        href = 'https://canada.siunsons.com/account/login'
                                        style = {{ color: '#ff5722' }} 
                                    >here</a>.
                                </li>
                                <li>
                                    International customers, register&nbsp;
                                    <a 
                                        href = 'https://international.siunsons.com/account/login'
                                        style = {{ color: '#ff5722' }} 
                                    >here</a>.
                                </li>
                            </ul>
                        </Collaspe>
                        <Collaspe
                            title = { 'What to do if I forget my password?' }
                            eventKey = { '3' }
                        >
                            <ul
                                style = {{
                                    listStyleType: 'none'
                                }}
                            >
                                <li>Step 1 Click "Forget Password"</li>
                                <li>Step 2 Put in your e-mail and "Submit"</li>
                                <li>Step 3 You'll receive a link in your e-mail to reset a new password</li>
                            </ul>
                            <div
                                style = {{
                                    position: 'relative',
                                    width: '100%',
                                    paddingTop: '50%',
                                    backgroundColor: 'red',
                                }}
                            >
                                <Image 
                                    alt = {'FAQ'}
                                    src = { require('../images/faq1.gif') }
                                />
                            </div>
                        </Collaspe>
                        <Collaspe
                            title = { 'What should I do if I experience any technical difficulty or have website enquiry?' }
                            eventKey = { '4' }
                        >
                            Please contact this e-mail: <a style = {{ color: '#ff5722' }} href = { 'mailto:tech@siunsons.com' }>tech@siunsons.com</a>
                        </Collaspe>
                        <Collaspe
                            title = { 'What if I need further assistance?' }
                            eventKey = { '5' }
                        >
                            <p>
                                Call 604-275-5428 or 1-800-383-4634<br/>
                                Press 2 for Accounts Receivable or to make a payment<br/>
                                Press 3 for Orders status<br/>
                                Press 4 for Sales<br/>
                            </p>
                        </Collaspe>
                        <Collaspe
                            title = { 'How to start placing order in just 3 clicks?' }
                            eventKey = { '6' }
                        >
                            Step 1 Visit the webstore at <a href = 'www.siunsons.com' style = {{ color: '#ff5722' }} >https://www.siunsons.com</a><br/>
                            Step 2 Search by item# or alternatively scan the UPC with a scanner or Visit Products / Web Specials / New Arrivals to start adding to cart<br/>
                            Step 3 Select the desired product and complete the order<br/>
                            <div
                                style = {{
                                    position: 'relative',
                                    width: '100%',
                                    paddingTop: '50%',
                                    backgroundColor: 'red',
                                }}
                            >
                                <Image 
                                    alt = {'FAQ'}
                                    src = { require('../images/faq2.gif') }
                                />
                            </div>
                        </Collaspe>
                        <Collaspe
                            title = { 'How to make re-order faster by scanning UPC into a search field? (scanner is required)' }
                            eventKey = { '7' }
                        >
                            At the "Search" bar at the top right corner, scan the barcode with a scanner and you'll be directed to the product ordering page.
                        </Collaspe>
                        <Collaspe
                            title = { 'How to keep me posted with Siu & sons specials?' }
                            eventKey = { '8' }
                        >
                            1. Subscribe S&amp;S e-marketing: (email address)<br/>
                            2. Subscribe S&amp;S text-marketing: (cell no.)
                        </Collaspe>
                        <Collaspe
                            title = { 'Is there free WIFI at your showroom?' }
                            eventKey = { '9' }
                        >
                            Yes, we have free WIFI at the showroom. You can enjoy yourself while we work diligently for your order.
                        </Collaspe>
                        <Collaspe
                            title = { 'Do you provide helium service?' }
                            eventKey = { '10' }
                        >
                            Yes, we offer a great variety of balloons, provide helium service, and make beautiful balloon bouquets to your order!
                        </Collaspe>
                    </Accordion>
                </div>
            </div>
        </Layout>
    )
}
export default Faq
